import { useStaticQuery, graphql } from 'gatsby';

const query = graphql`
   query SiteMetaData {
      site {
         siteMetadata {
            author {
               name
               bio
               photo
               contacts {
                  linkedin
                  github
                  email
               }
            }
            url
            title
            subtitle
            copyright
            disqusShortname
         }
      }
   }
`;

const useSiteMetadata = () => {
   const { site } = useStaticQuery<GatsbyTypes.SiteMetaDataQuery>(query);
   return site?.siteMetadata;
};

export default useSiteMetadata;
