import { useStaticQuery, graphql } from 'gatsby';

const query = graphql`
   query CategoriesList {
      allMarkdownRemark(
         filter: { frontmatter: { template: { eq: "post" }, draft: { ne: true } } }
      ) {
         group(field: frontmatter___category) {
            fieldValue
            totalCount
         }
      }
   }
`;

const useCategoriesList = () => {
   const { allMarkdownRemark } = useStaticQuery<GatsbyTypes.CategoriesListQuery>(query);
   return allMarkdownRemark.group;
};

export default useCategoriesList;
