import { useStaticQuery, graphql } from 'gatsby';

const query = graphql`
   query ResumeFile {
      file(name: { eq: "2021_Zhongyuan_Resume" }) {
         publicURL
      }
   }
`;

const useResumeFile = () => {
   const { file } = useStaticQuery<GatsbyTypes.ResumeFileQuery>(query);
   return file?.publicURL;
};

export default useResumeFile;
